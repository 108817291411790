import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const ProtectedRoute = ({ children, page }) => {
  const logged = useSelector((state) => state.aplicationConfig.userConfig.logged);

  if (logged && logged) {
    // user is not authenticated
    return children;
  } else {
    return <Navigate to="/login" />;
  }
};
