import React from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineCreditCard, HiOutlineTrash } from "react-icons/hi";
import "./style.css";
import { Button } from "../../../../components/button";

export const Payments = () => {
  const navigate = useNavigate();
  const handleMenu = (component) => {
    navigate("/" + component);
  };

  const mockPaymentsMethods = [{ id: 0, name: "Tarjeta de Crédito/Débito", number: "436" }];

  const PaymentMethod = ({ data }) => {
    return (
      <div className="paymentMethod">
        <div className="paymentMethod-name">{data.name}</div>
        <div className="paymentMethod-info">
          <div className="paymentMethod-details">
            <div className="paymentMethod-info-icon">
              <HiOutlineCreditCard />
            </div>
            <div className="paymentMethod-info-number">***************{data.number}</div>
          </div>
          <div className="paymentMethod-info-action">
            <HiOutlineTrash />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="profile-container">
      <div className="profile-container-header">
        <h2>Métodos de pago</h2>
        <div>
          <AiOutlineClose onClick={() => handleMenu("profile")} />
        </div>
      </div>
      <div className="payments-body">
        {mockPaymentsMethods.map((payment) => (
          <PaymentMethod data={payment} />
        ))}
        <Button text={"Agregar tarjeta"} icon={null} customClass={"customButtonColor2"} action={() => null} />
      </div>
    </div>
  );
};
