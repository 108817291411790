import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { MdOutlineDeliveryDining } from "react-icons/md";
import { Button } from "./../../../../components/button";
import "./style.css";

export const OrderTraking = () => {
  const mockTimeline = [
    { id: 0, name: "Su pedido ha sido aceptado", date: "2 min" },
    { id: 1, name: "Se está preparando su pedido", date: "3 min" },
    { id: 2, name: "La entrega está en camino", date: "15 min" },
  ];

  const paramsUrl = useParams();
  const [order, setOrder] = useState({});
  const navigate = useNavigate();
  const handleMenu = (component) => {
    navigate("/" + component);
  };

  useEffect(() => {
    if (paramsUrl && paramsUrl.id) {
      setOrder({ id: paramsUrl.id, direction: "Calle Alameda 12, Málaga, 29108" });
    }
  }, [paramsUrl]);

  const Timeline = () => {
    return (
      <>
        <div className="timeline-container">
          <ul className="timeline">
            {mockTimeline.map((timeline) => (
              <li>
                <div className="circleRounded">
                  <div className="circle"></div>
                </div>
                <div className="event">
                  <div className="event-name">{timeline.name}</div>
                  <div className="event-date">{timeline.date}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  };
  const FooterActions = () => {
    return (
      <div className="footerActions">
        <Button text={"Llamar a la tienda"} icon={null} customClass={"customButtonColor2"} action={() => null} />
      </div>
    );
  };

  return (
    <div className="profile-container">
      <div className="profile-container-header">
        <h2>Seguimiento de pedido</h2>
        <div>
          <AiOutlineClose onClick={() => handleMenu("history")} />
        </div>
      </div>
      <div className="order-tracking-body">
        <div className="order-tracking-state">
          <MdOutlineDeliveryDining className="moto-icon" />
          <div className="road"></div>
        </div>
        <div className="order-tracking-body-content">
          <div>
            <div className="order-tracking-body-content-title">Dirección de entrega</div>
            <div>{order.direction}</div>
          </div>
          <div>
            <div className="order-tracking-body-content-title">Tiempo de entrega estimada</div>
            <div>25 min</div>
          </div>
          <Timeline />
        </div>
      </div>
      <FooterActions />
    </div>
  );
};
