//Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCart, updateFloatPanelVisibility, setFloatPanelData } from "./../../reducers/userConfigSlice";
//Components
//import { Select } from "./../../components/select";
//import Switch from "@mui/material/Switch";
import { HiOutlineSearch } from "react-icons/hi";
import { FloatPanelTemplate } from "./../../components/floatPanel/floatPanelTemplate";
import { Textarea } from "../../components/inputs/textarea";
import { Button } from "../../components/button";
import { LuCheck, LuMinus, LuPlus, LuX } from "react-icons/lu";
import { IoMdClose } from "react-icons/io";
/*import { CustomBlock } from "./../../components/customBlock";
import { Textarea } from "./../../components/textarea/type1";
import { Input } from "./../../components/inputs/type2";
import { DraggableList } from "./../../components/draggableList";
import { NumberInputCustom } from "./../../components/inputs/type3";*/

export const FloatComponent = ({ data }) => {
  const cart = useSelector((state) => state.aplicationConfig.userConfig.cart);
  const existingItem = cart.find((item) => item.id === data.id);
  const dispatch = useDispatch();

  const closeFloatPanelVisibility = () => {
    dispatch(updateFloatPanelVisibility());
    dispatch(setFloatPanelData({}));
  };

  const addCart = (newItem) => {
    const handleCart = (prevItems) => {
      const existingItem = prevItems.find((item) => item.id === newItem.id);

      if (existingItem) {
        // Si el producto ya está en el carrito, incrementa la cantidad
        return prevItems.map((item) => (item.id === newItem.id ? { ...item, quantity: item.quantity + 1 } : item));
      } else {
        // Si el producto no está en el carrito, agrégalo con quantity: 1
        return [...prevItems, { ...newItem, quantity: 1 }];
      }
    };

    dispatch(updateCart(handleCart(cart)));
  };

  const removeFromCart = (itemToRemove) => {
    const handleCart = (prevItems) => {
      const existingItem = prevItems.find((item) => item.id === itemToRemove.id);

      if (existingItem) {
        if (existingItem.quantity > 1) {
          // Si el producto tiene más de 1 en cantidad, reduce la cantidad en 1
          return prevItems.map((item) => (item.id === itemToRemove.id ? { ...item, quantity: item.quantity - 1 } : item));
        } else {
          // Si el producto tiene solo 1 en cantidad, lo elimina del carrito
          return prevItems.filter((item) => item.id !== itemToRemove.id);
        }
      }
    };

    dispatch(updateCart(handleCart(cart)));
  };

  const Content = () => {
    return (
      <div className="details-content">
        <div className="details-image"></div>
        <div className="details-name">{data.name}</div>
        <div className="details-description">{data.description}</div>

        <div className="details-block">
          <div className="details-name">Variantes</div>
          <div className="details-block-list">
            <div>
              <input type="radio" /> Peperoni
            </div>
            <div>
              <input type="radio" /> Peperoni y champiñones
            </div>
          </div>
        </div>

        <div className="details-block">
          <div className="details-name">Añadir notas</div>
          <Textarea id="notes" />
        </div>

        <div className="details-block">
          <div className="details-resume">
            <div className="details-resume-total">{existingItem ? (existingItem.quantity * existingItem.price).toFixed(2) : "0.00"}€</div>
            <div className="details-resume-actions">
              <LuMinus onClick={() => existingItem && removeFromCart(data)} />
              <div className="product-name">{existingItem ? existingItem.quantity : "0"}</div>
              <LuPlus onClick={() => addCart(data)} />
            </div>
          </div>
          {/*<Button text={"Añadir"} icon={null} customClass={"customButtonColor2"} action={() => null} />*/}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="floatPanelHeader">
        <div className="floatPanelHeaderLeft">
          <div></div>
        </div>
        <div className="floatPanelHeaderRight">
          <div onClick={closeFloatPanelVisibility}>
            <IoMdClose />
          </div>
        </div>
      </div>

      <div className="floatPanelBody">{<Content />}</div>
    </>
  );
};
