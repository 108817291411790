import { useState } from "react";
import "./style.css";

export const Input = ({
  id,
  placeholder,
  type = "text",
  icon = null,
  iconType = null,
  classname = "",
  iconPositionRight = true,
  label,
  description,
  onChange = () => null,
  disabled,
}) => {
  const [inputType, setInputType] = useState(type);

  const handleIcon = () => {
    if (iconType === "tooglePasswordVisibility") {
      inputType === "text" ? setInputType("password") : setInputType("text");
    }
  };

  const classIcon = iconPositionRight ? " inputRight " : " iconLeft ";
  const iconAction = iconType === null ? "" : " actionIcon ";
  const inputSpaceAtStart = iconPositionRight ? "" : " inputSpaceAtStart ";

  return (
    <div className="inputType2Container">
      <div className="inputLabel">{label}</div>
      <div className="inputDescription">{description}</div>
      <input
        id={id}
        type={inputType}
        placeholder={placeholder}
        className={"inputType2 " + classname + inputSpaceAtStart}
        onChange={onChange}
        disabled={disabled}
      />
      <div className={classIcon + iconAction} onClick={handleIcon}>
        {icon}
      </div>
    </div>
  );
};
