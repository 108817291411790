import React from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { CiShoppingBasket } from "react-icons/ci";
import "./style.css";
import { Button } from "../../../../components/button";

export const History = () => {
  const navigate = useNavigate();
  const handleMenu = (component) => {
    navigate("/" + component);
  };

  const mockOrder = [
    { id: 0, code: "31542-F2", direction: "Calle Alameda 12, Málaga, 29108", date: "05 de Julio de 2024", state: 0, total: 24 },
    { id: 0, code: "31542-F1", direction: "Calle Alameda 12, Málaga, 29108", date: "04 de Julio de 2024", state: 1, total: 22 },
  ];

  const Order = ({ order }) => {
    const OrderStates = ({ state }) => {
      switch (state) {
        case 0:
          return <div className="orderState order-state-info">En ruta</div>;
        case 1:
          return <div className="orderState order-state-success">Entregado</div>;
        default:
          break;
      }
    };
    return (
      <div className="history-order">
        <div className="history-order-header">
          <div className="history-order-icon">
            <CiShoppingBasket />
          </div>
          <div className="history-order-details">
            <div className="history-order-details-code">{order.code}</div>
            <div className="history-order-details-direction">{order.direction}</div>
            <div className="history-order-details-date">{order.date}</div>
          </div>
        </div>
        <div className="history-order-body">
          <div className="history-order-body-state">
            <div className="history-order-body-state-label">Estado del pedido</div>
            <div className="history-order-body-state-value">
              <OrderStates state={order.state} />
            </div>
          </div>
          <div className="history-order-body-total">
            <div className="history-order-body-total-label">Total</div>
            <div className="history-order-body-total-value">{order.total.toFixed(2)} €</div>
          </div>
        </div>
        {order.state === 0 && (
          <div className="history-order-action">
            <Button
              text={"Rastrear pedido"}
              icon={null}
              customClass={"customButtonColor2"}
              action={() => handleMenu("order-tracking/" + order.code)}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="profile-container">
      <div className="profile-container-header">
        <h2>Historial de pedidos</h2>
        <div>
          <AiOutlineClose onClick={() => handleMenu("profile")} />
        </div>
      </div>
      <div className="history-body">
        {mockOrder.map((order) => (
          <Order order={order} />
        ))}
      </div>
    </div>
  );
};
