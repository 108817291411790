import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCart, updateFloatPanelVisibility, setFloatPanelData } from "./../../reducers/userConfigSlice";
//Components
import { FloatPanel } from "../../components/floatPanel";
import { LuCheck, LuMinus, LuPlus, LuX } from "react-icons/lu";
import "./style.css";
import { FloatComponent } from "./floatComponent";

export const Products = () => {
  const paramsUrl = useParams();
  const [menuSelected, setMenuSelected] = useState(0);
  const floatPanel = useSelector((state) => state.aplicationConfig.userConfig.floatPanel);
  const divRefs = useRef([]);
  const dispatch = useDispatch();

  const categoryList = [
    { id: 0, name: "Ofertas" },
    { id: 1, name: "Pizzas" },
    { id: 2, name: "Hamburgesas" },
    { id: 3, name: "Tapas" },
    { id: 4, name: "Bebidas" },
    { id: 5, name: "Postres" },
  ];
  const productList = [
    { id: 0, name: "Nombre corto", description: "Lorep ipsum dolor sit aemt, cosectetir adipspcuen elot, se d do it.", price: 25, category: 0 },
    { id: 1, name: "Nombre corto", description: "Lorep ipsum dolor sit aemt, cosectetir adipspcuen elot, se d do it.", price: 25, category: 0 },
    { id: 2, name: "Nombre corto", description: "Lorep ipsum dolor sit aemt, cosectetir adipspcuen elot, se d do it.", price: 25, category: 0 },
    { id: 3, name: "Nombre corto", description: "Lorep ipsum dolor sit aemt, cosectetir adipspcuen elot, se d do it.", price: 25, category: 1 },
    { id: 4, name: "Nombre corto", description: "Lorep ipsum dolor sit aemt, cosectetir adipspcuen elot, se d do it.", price: 25, category: 1 },
    { id: 5, name: "Nombre corto", description: "Lorep ipsum dolor sit aemt, cosectetir adipspcuen elot, se d do it.", price: 25, category: 1 },
    { id: 6, name: "Nombre corto", description: "Lorep ipsum dolor sit aemt, cosectetir adipspcuen elot, se d do it.", price: 25, category: 2 },
    { id: 7, name: "Nombre corto", description: "Lorep ipsum dolor sit aemt, cosectetir adipspcuen elot, se d do it.", price: 25, category: 2 },
    { id: 8, name: "Nombre corto", description: "Lorep ipsum dolor sit aemt, cosectetir adipspcuen elot, se d do it.", price: 25, category: 2 },
    { id: 9, name: "Nombre corto", description: "Lorep ipsum dolor sit aemt, cosectetir adipspcuen elot, se d do it.", price: 25, category: 3 },
    { id: 10, name: "Nombre corto", description: "Lorep ipsum dolor sit aemt, cosectetir adipspcuen elot, se d do it.", price: 25, category: 3 },
    { id: 11, name: "Nombre corto", description: "Lorep ipsum dolor sit aemt, cosectetir adipspcuen elot, se d do it.", price: 25, category: 3 },
    { id: 12, name: "Nombre corto", description: "Lorep ipsum dolor sit aemt, cosectetir adipspcuen elot, se d do it.", price: 25, category: 3 },
    { id: 13, name: "Nombre corto", description: "Lorep ipsum dolor sit aemt, cosectetir adipspcuen elot, se d do it.", price: 25, category: 3 },
    { id: 14, name: "Nombre corto", description: "Lorep ipsum dolor sit aemt, cosectetir adipspcuen elot, se d do it.", price: 25, category: 3 },
  ];

  function goToCategoryList(id, offset = 70) {
    const element = document.getElementById(id);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }

  const Menu = () => {
    return (
      <div className="productsMenu">
        {categoryList.map((item) => (
          <div
            onClick={() => goToCategoryList("category-" + item.id)}
            className={menuSelected === item.id ? "productMenu-option productMenu-option-selected" : "productMenu-option"}
          >
            {item.name}
          </div>
        ))}
      </div>
    );
  };

  const ProductList = () => {
    const cart = useSelector((state) => state.aplicationConfig.userConfig.cart);
    const addCart = (newItem) => {
      const handleCart = (prevItems) => {
        const existingItem = prevItems.find((item) => item.id === newItem.id);

        if (existingItem) {
          // Si el producto ya está en el carrito, incrementa la cantidad
          return prevItems.map((item) => (item.id === newItem.id ? { ...item, quantity: item.quantity + 1 } : item));
        } else {
          // Si el producto no está en el carrito, agrégalo con quantity: 1
          return [...prevItems, { ...newItem, quantity: 1 }];
        }
      };

      dispatch(updateCart(handleCart(cart)));
    };

    const removeFromCart = (itemToRemove) => {
      const handleCart = (prevItems) => {
        const existingItem = prevItems.find((item) => item.id === itemToRemove.id);

        if (existingItem) {
          if (existingItem.quantity > 1) {
            // Si el producto tiene más de 1 en cantidad, reduce la cantidad en 1
            return prevItems.map((item) => (item.id === itemToRemove.id ? { ...item, quantity: item.quantity - 1 } : item));
          } else {
            // Si el producto tiene solo 1 en cantidad, lo elimina del carrito
            return prevItems.filter((item) => item.id !== itemToRemove.id);
          }
        }
      };

      dispatch(updateCart(handleCart(cart)));
    };

    const Product = ({ data }) => {
      const existingItem = cart.find((item) => item.id === data.id);

      const handleFloatPanelVisibility = (data = null) => {
        dispatch(updateFloatPanelVisibility());
        dispatch(setFloatPanelData(data));
      };

      //const Component = <FloatComponent data={data} existingItem={existingItem} setFloatPanelVisible={setFloatPanelVisible(false)} />;

      return (
        <>
          <div className={existingItem ? "product product-selected" : "product"}>
            {/*existingItem && <div className="product-quantity-purchased">{existingItem.quantity}</div>*/}
            <div className="product-image" onClick={() => handleFloatPanelVisibility(data)}></div>
            <div className="product-info">
              <div className="product-details">
                <div className="product-name">{data.name}</div>
                <div className="product-description">{data.description}</div>
              </div>
              <div className="product-details-bottom">
                <div className="product-name">{data.price.toFixed(2)}€</div>
                <div className="product-actions">
                  <LuMinus onClick={() => existingItem && removeFromCart(data)} />
                  <div className="product-name">{existingItem ? existingItem.quantity : "0"}</div>
                  <LuPlus onClick={() => addCart(data)} />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    };

    // Agrega las referencias de los divs al array divRefs
    useEffect(() => {
      divRefs.current = divRefs.current.slice(0, categoryList.length);
    }, [categoryList]);

    // Actualiza el estado con el id del div visible
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && entry.boundingClientRect.top <= 125) {
              setMenuSelected(parseInt(entry.target.id.replace("category-", "")));
            }
          });
        },
        { rootMargin: "0px 0px -90% 0px", threshold: 0 }
      );

      divRefs.current.forEach((div) => {
        if (div) observer.observe(div);
      });

      return () => {
        observer.disconnect();
      };
    }, []);

    return (
      <div className="productList">
        {categoryList.map(
          (category) =>
            productList.filter((f) => f.category === category.id).length > 0 && (
              <>
                <h2 id={"category-" + category.id} key={"category-" + category.id} ref={(el) => (divRefs.current[category.id] = el)}>
                  {category.name}
                </h2>
                {productList.map((item) => item.category === category.id && <Product data={item} />)}
              </>
            )
        )}
      </div>
    );
  };

  useEffect(() => {
    if (paramsUrl && paramsUrl.category && paramsUrl.category !== "") {
      const categoryFound = categoryList.filter((f) => f.name.toLowerCase() === paramsUrl.category.toLowerCase());
      if (categoryFound.length > 0) {
        goToCategoryList("category-" + categoryFound[0].id);
      }
    }
  }, [paramsUrl]);

  return (
    <div className="products-container">
      <Menu />
      <ProductList />
      {floatPanel.visible && <FloatPanel component={<FloatComponent data={floatPanel.data} />} />}
    </div>
  );
};
