import React from "react";
import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch } from "react-redux";
import { setUserLogOut } from "../../reducers/userConfigSlice";
//Components
import { GoPerson } from "react-icons/go";
import "./style.css";

export const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOff = async () => {
    dispatch(setUserLogOut());
    navigate("/");
  };

  const handleMenu = (component) => {
    if (component === "logout") logOff();
    else navigate("/" + component);
  };

  return (
    <div className="profile-container">
      <h2>Mi cuenta</h2>
      <div className="profile-card">
        <div className="profile-card-header">
          <div className="profile-card-avatar">
            <GoPerson />
          </div>
          <div className="profile-card-name">Nayara Ruiz</div>
          <div className="profile-card-phone">+34 689 897 415</div>
        </div>
        <div className="profile-card-body">
          <div className="profile-card-menu" onClick={() => handleMenu("details")}>
            <GoPerson /> Detalles de mi cuenta
          </div>
          <div className="profile-card-menu" onClick={() => handleMenu("history")}>
            <GoPerson /> Historial de pedidos
          </div>
          <div className="profile-card-menu" onClick={() => handleMenu("payments")}>
            <GoPerson /> Métodos de pago
          </div>
          <div className="profile-card-menu" onClick={() => handleMenu("policies")}>
            <GoPerson /> Políticas de privacidad
          </div>
          <div className="profile-card-menu menu-logout" onClick={() => handleMenu("logout")}>
            <GoPerson /> Salir
          </div>
        </div>
      </div>
    </div>
  );
};
