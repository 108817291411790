import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCart, setComponentActive } from "./../../reducers/userConfigSlice";
import { Button } from "../../components/button";
import "./style.css";

export const ResponsePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const CheckoutAction = () => {
    const handleMenu = (component) => {
      dispatch(setComponentActive(component));
      navigate("/" + component);
    };
    return (
      <div className="checkout-resume-total">
        <Button text={"Seguimiento del pedido"} icon={null} customClass={"customButtonColor2"} action={() => handleMenu("profile")} />
      </div>
    );
  };

  return (
    <div className="checkout-container">
      <div>¡Pedido realizado!</div>
      <CheckoutAction />
    </div>
  );
};
