import { useState } from "react";
import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setComponentActive, setUserLogOut } from "../../reducers/userConfigSlice";

import Logo from "./../../assets/logo/logo-delibran.svg";
import { CiSearch, CiShoppingBasket } from "react-icons/ci";
import "./style.css";

export const HeaderMobile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMenu = (component) => {
    dispatch(setComponentActive(component));
    navigate("/" + component);
  };
  return (
    <div className="shopHeader">
      <div>
        <CiSearch />
      </div>
      <div>
        <img src={Logo} alt="logo" className="brand" />
      </div>
      <div>
        <CiShoppingBasket onClick={() => handleMenu("carrito")} />
      </div>
    </div>
  );
};
