import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
//Components
import { Input } from "./../../../../components/inputs/type2";
import { Button } from "./../../../../components/button";
import { AiOutlineClose } from "react-icons/ai";
import "./style.css";

export const Details = () => {
  const [saveChanges, setSaveChanges] = useState(false);
  const navigate = useNavigate();
  const handleMenu = (component) => {
    navigate("/" + component);
  };

  const handleSaveChanges = (data) => {
    if (data.target) {
      //data.target.value
      setSaveChanges(true);
    }
  };

  const updateChanges = () => {
    setSaveChanges(false);
  };

  const FooterActions = () => {
    return (
      <div className="footerActions">
        <Button text={"Cancelar"} icon={null} customClass={"customButtonColor1"} action={updateChanges} />
        <Button text={"Guardar"} icon={null} customClass={"customButtonColor2"} action={updateChanges} />
      </div>
    );
  };

  return (
    <div className="profile-container">
      <div className="profile-container-header">
        <h2>Perfil</h2>
        <div>
          <AiOutlineClose onClick={() => handleMenu("profile")} />
        </div>
      </div>
      <div className="profile-details-body">
        <div className="profile-details-avatar"></div>
        <Input
          id={"nameInput"}
          type={"text"}
          label={"Nombre completo"}
          placeholder=""
          disabled={""}
          onKeyPress={() => null}
          icon={null}
          iconType={""}
          classname={""}
          onChange={handleSaveChanges}
        />
        <Input
          id={"emailInput"}
          type={"text"}
          label={"Email"}
          placeholder=""
          disabled={""}
          onKeyPress={() => null}
          icon={null}
          iconType={""}
          classname={""}
          onChange={handleSaveChanges}
        />
        <Input
          id={"telInput"}
          type={"text"}
          label={"Teléfono"}
          placeholder=""
          disabled={""}
          onKeyPress={() => null}
          icon={null}
          iconType={""}
          classname={""}
          onChange={handleSaveChanges}
        />
        <Input
          id={"direccionInput"}
          type={"text"}
          label={"Dirección de envío"}
          placeholder=""
          disabled={""}
          onKeyPress={() => null}
          icon={null}
          iconType={""}
          classname={""}
          onChange={handleSaveChanges}
        />
        <Input
          id={"passwordInput"}
          type={"password"}
          label={"Contraseña"}
          placeholder=""
          disabled={true}
          onKeyPress={() => null}
          icon={null}
          iconType={""}
          classname={""}
          onChange={handleSaveChanges}
        />
        <div className="profile-details-changePassword">Cambiar contraseña</div>
      </div>
      {saveChanges && <FooterActions />}
    </div>
  );
};
