import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//Sections
import Login from "./pages/login";
import { Home } from "./pages/home";
import { Products } from "./pages/products";
import { Categories } from "./pages/categories";
import { Profile } from "./pages/profile";
import { Cart } from "./pages/cart";
import { ProtectedRoute } from "./components/security/protectedRoute";
import { MenuMobile } from "./components/menuMobile";
import { HeaderMobile } from "./components/headerMobile";
import { FloatPanel } from "./components/floatPanel";
import { Checkout } from "./pages/checkout";
import { ResponsePage } from "./pages/responsePage";
import { Details } from "./pages/profile/pages/details";
import { History } from "./pages/profile/pages/history";
import { Payments } from "./pages/profile/pages/payments";
import { Policies } from "./pages/profile/pages/policies";
import { OrderTraking } from "./pages/profile/pages/history/orderTraking";

//Functions
//import useLanguage from "./functions/useLanguage";

function App() {
  //useLanguage();

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HeaderMobile />
                <MenuMobile />
                <Home />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <HeaderMobile />
                <MenuMobile />
                <Login />
              </>
            }
          />
          <Route
            path="/categories"
            element={
              <>
                <HeaderMobile />
                <MenuMobile />
                <Categories />
              </>
            }
          />
          <Route
            path="/products/:category?"
            element={
              <>
                <HeaderMobile />
                <MenuMobile />
                <Products />
              </>
            }
          />
          <Route
            path="/carrito"
            element={
              <>
                <HeaderMobile />
                <MenuMobile />
                <Cart />
              </>
            }
          />

          <Route
            path="/profile"
            element={
              <ProtectedRoute page="profile">
                <HeaderMobile />
                <MenuMobile />
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkout"
            element={
              <ProtectedRoute page="checkout">
                <HeaderMobile />
                <MenuMobile />
                <Checkout />
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkoutPage"
            element={
              <ProtectedRoute page="checkoutPage">
                <HeaderMobile />
                <MenuMobile />
                <ResponsePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/details"
            element={
              <ProtectedRoute page="details">
                <HeaderMobile />
                <MenuMobile />
                <Details />
              </ProtectedRoute>
            }
          />
          <Route
            path="/history"
            element={
              <ProtectedRoute page="history">
                <HeaderMobile />
                <MenuMobile />
                <History />
              </ProtectedRoute>
            }
          />
          <Route
            path="/order-tracking/:id?"
            element={
              <ProtectedRoute page="order-tracking">
                <HeaderMobile />
                <MenuMobile />
                <OrderTraking />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payments"
            element={
              <ProtectedRoute page="payments">
                <HeaderMobile />
                <MenuMobile />
                <Payments />
              </ProtectedRoute>
            }
          />
          <Route
            path="/policies"
            element={
              <ProtectedRoute page="policies">
                <HeaderMobile />
                <MenuMobile />
                <Policies />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
