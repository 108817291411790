import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setComponentActive, setUserLogOut } from "../../reducers/userConfigSlice";

import { MdKeyboardArrowRight } from "react-icons/md";
import "./style.css";

export const ItemList = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleUrl = (url) => {
    if (url.includes("/")) {
      dispatch(setComponentActive(url.split("/")[0]));
    } else {
      dispatch(setComponentActive(url));
    }
    navigate("/" + url);
  };
  return (
    <div className="itemList" onClick={() => handleUrl(data.url)}>
      <div class="itemListImage" style={{ backgroundImage: `url(${data.img})` }}></div>
      <div className="itemListName">{data.name}</div>
    </div>
  );
};

export const RowItemList = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleUrl = (url) => {
    dispatch(setComponentActive(url));
    navigate("/" + url);
  };
  return (
    <div className="rowItemList">
      <div className="rowItemListHeader">
        <div>
          <h2>{data.name}</h2>
        </div>
        <div className="actionLabel" onClick={() => handleUrl(data.url)}>
          Ver todos <MdKeyboardArrowRight />
        </div>
      </div>
      <div className="rowItemListBody">
        {data.list.map((item) => (
          <ItemList data={item} />
        ))}
      </div>
    </div>
  );
};
