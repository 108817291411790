import { CarouselComponent } from "../../components/carousel";
import { RowItemList } from "../../components/rowItemList";
import "./style.css";

export const Home = () => {
  const dataMockCarousel = [
    { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Pizza Pepperoni", url: "#" },
    { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Pizza Pepperoni", url: "#" },
    { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Pizza Pepperoni", url: "#" },
  ];

  const dataMockCategorias = {
    name: "Categorías",
    url: "categories",
    list: [
      { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Pizzas", url: "products/pizzas" },
      { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Pizzas", url: "products/pizzas" },
      { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Pizzas", url: "products/pizzas" },
    ],
  };

  const dataMockProducts = {
    name: "Productos destacados",
    url: "products",
    list: [
      { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Pizzas", url: "products/pizzas" },
      { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Pizzas", url: "products/pizzas" },
      { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Pizzas", url: "products/pizzas" },
    ],
  };

  const dataMockSales = {
    name: "Ofertas",
    url: "products",
    list: [
      { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Pizzas", url: "products/pizzas" },
      { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Pizzas", url: "products/pizzas" },
      { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Pizzas", url: "products/pizzas" },
    ],
  };

  return (
    <div className="homeContainer">
      {/*<CarouselComponent data={dataMockCarousel} />*/}
      <RowItemList data={dataMockSales} />
      <RowItemList data={dataMockCategorias} />
      <RowItemList data={dataMockProducts} />
    </div>
  );
};
