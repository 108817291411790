import { Carousel } from "react-responsive-carousel";
import { MdKeyboardArrowRight } from "react-icons/md";
import "./style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export const CarouselComponent = ({ data }) => {
  const handleUrl = () => {
    return null;
  };
  return (
    <div className="box">
      <Carousel useKeyboardArrows={false} swipeable={true} transitionTime={310} swipeMovementStarted={true}>
        {data.map((item, index) => (
          <div className="slide">
            <img alt={"featured_" + index} src={item.img} key={index} />
            <div className="featured-info">
              <div className="featured-title">{item.name}</div>
              <div className="featured-action" onClick={() => handleUrl(item.url)}>
                Ver <MdKeyboardArrowRight />
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};
