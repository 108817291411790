import { useState } from "react";
import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setComponentActive, setUserLogOut } from "../../reducers/userConfigSlice";
//Components
import { GoHome, GoPerson } from "react-icons/go";
import { LuHome } from "react-icons/lu";
import { VscPackage } from "react-icons/vsc";
import { PiPackage } from "react-icons/pi";
//Styles
import "./style.css";

export const MenuMobile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const componentActive = useSelector((state) => state.aplicationConfig.userConfig.componentActive);
  const [menuMobile, showMenuMobile] = useState(false);

  const logOff = async () => {
    dispatch(setUserLogOut());
    navigate("/");
  };

  /*const optionsMenuMobile = [
    { id: 1, name: "Clientes", icon: <GoPerson />, component: "" },
    { id: 1, name: "Descuentos", icon: <GoPerson />, component: "" },
    { id: 1, name: "Informes y estadísticas", icon: <BsBarChart />, component: "" },
    { id: 1, name: "Pagos", icon: <PiMoney />, component: "" },
    { id: 1, name: "Configuración", icon: <ImCog />, component: "" },
  ];*/

  const handleMenu = (component) => {
    if (component === "logout") logOff();
    else {
      dispatch(setComponentActive(component));
      navigate("/" + component);
    }
  };

  /*const handleMobileMenu = () => {
    console.log("handleMobileMenu");
    showMenuMobile(!menuMobile);
  };*/

  /*const BadgeMenuMobile = () => {
    return (
      <div className="badgeMenuMobile">
        {<div className="closeBadgeMenuMobile">
          <div onClick={() => handleMobileMenu()}></div>
    </div>}
        <div style={{ textAlign: "end", cursor: "pointer", padding: "0px 23px" }}>
          <IoCloseOutline onClick={() => handleMobileMenu()} />
        </div>
        {optionsMenuMobile.map((menu) => {
          return (
            <div>
              {menu.icon} <label>{menu.name}</label>
            </div>
          );
        })}
      </div>
    );
  };*/

  return (
    <div className="bottom-toolbar">
      <div className={componentActive === "" ? "optionMenuMobile optionMenuMobileActive " : "optionMenuMobile"}>
        <GoHome onClick={() => handleMenu("")} />
      </div>
      <div className={componentActive === "products" ? "optionMenuMobile optionMenuMobileActive " : "optionMenuMobile"}>
        <PiPackage onClick={() => handleMenu("products")} />
      </div>
      <div className={componentActive === "profile" ? "optionMenuMobile optionMenuMobileActive " : "optionMenuMobile"}>
        <GoPerson onClick={() => handleMenu("profile")} />
      </div>
      {/*<div>
        <LiaBarsSolid className={menuMobile ? " optionMenuMobileActive " : ""} onClick={() => handleMobileMenu()} />
      </div>*/}
      {/*menuMobile && (
        <>
          <div className="shadowBackground"></div>
          <BadgeMenuMobile />
        </>
      )*/}
    </div>
  );
};
