//Redux
import { useSelector } from "react-redux";
import "./style.css";

export const FloatPanel = ({ component }) => {
  //const floatPanel = useSelector((state) => state.aplicationConfig.userConfig.floatPanel);

  const Panel = () => {
    return (
      <>
        <div className={`floatPanel scale-up-ver-top`}>{component}</div>
        <div className="shadow"></div>
      </>
    );
  };

  return <Panel />;
};
