import { AllItemList } from "../../components/allItemList";
import { RowItemList } from "../../components/rowItemList";
import "./style.css";

export const Categories = () => {
  const dataMockCategorias = {
    name: "Categorias",
    url: "",
    list: [
      { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Pizzas", url: "products/pizzas" },
      { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Hamburgesas", url: "products/hamburgesas" },
      { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Cafés", url: "products/cafes" },
      { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Empanadas", url: "products/pizzas" },
      { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Refrescos", url: "products/pizzas" },
      { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Ensaladas", url: "products/pizzas" },
      { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Postres", url: "products/pizzas" },
      { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Kebab", url: "products/pizzas" },
      { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Pasta", url: "products/pizzas" },
      { img: "https://www.hola.com/horizon/landscape/e9e1e82cb873-pepperoni-pizza-abob-t.jpg", name: "Helados", url: "products/pizzas" },
      {},
      {},
    ],
  };
  return (
    <div className="homeContainer">
      <RowItemList data={dataMockCategorias} />
    </div>
  );
};
