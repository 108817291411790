import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { CgLoadbarDoc } from "react-icons/cg";
import "./style.css";

export const Policies = () => {
  const [policyOpen, setPolicyOpen] = useState(null);
  const navigate = useNavigate();
  const handleMenu = (component) => {
    navigate("/" + component);
  };

  const policiesList = [
    { id: 0, name: "Política de devolución y reembolso" },
    { id: 1, name: "Política de privacidad" },
    { id: 2, name: "Términos del servicio" },
    { id: 3, name: "Política de envío" },
    { id: 4, name: "Información de contacto" },
    { id: 5, name: "Aviso legal" },
  ];

  const lorepIpsum =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque at ante vitae neque feugiat tincidunt. Vivamus non sapien sit amet urna dictum suscipit. Maecenas at turpis magna. Donec non risus id lorem facilisis suscipit. Integer ac sapien in felis luctus facilisis. Curabitur sit amet arcu eu est lacinia bibendum. Nam convallis orci sed nibh pulvinar, vitae tristique eros egestas. Nullam non mi ac erat tristique ullamcorper. Morbi eget arcu vitae nulla fermentum malesuada. Aliquam erat volutpat. Phasellus ut libero sed velit fermentum dignissim in ac sapien. Ut euismod tortor at ligula lacinia, vel facilisis elit facilisis.";

  const PolicyItem = ({ policy }) => {
    return (
      <div className="policyContainer" onClick={policyOpen === policy.id ? () => setPolicyOpen(null) : () => setPolicyOpen(policy.id)}>
        <div className="policyItem">
          <div className="policyItem-icon">
            <CgLoadbarDoc />
          </div>
          <div className="policyItem-name">{policy.name}</div>
          <div className="policyItem-action">{policyOpen === policy.id ? <IoIosArrowUp /> : <IoIosArrowDown />}</div>
        </div>
        {policyOpen === policy.id && (
          <div className="policyText">
            <hr />
            {lorepIpsum}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="profile-container">
      <div className="profile-container-header">
        <h2>Políticas de privacidad</h2>
        <div>
          <AiOutlineClose onClick={() => handleMenu("profile")} />
        </div>
      </div>
      <div className="policies-body">
        {policiesList.map((policy) => (
          <PolicyItem policy={policy} />
        ))}
      </div>
    </div>
  );
};
