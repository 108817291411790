import React from "react";
import { useNavigate } from "react-router-dom";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCart, setComponentActive } from "./../../reducers/userConfigSlice";
//Components
import { LuMinus, LuPlus } from "react-icons/lu";
import "./style.css";
import { Button } from "../../components/button";

export const Cart = () => {
  const cart = useSelector((state) => state.aplicationConfig.userConfig.cart);
  const logged = useSelector((state) => state.aplicationConfig.userConfig.logged);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addCart = (newItem) => {
    const handleCart = (prevItems) => {
      const existingItem = prevItems.find((item) => item.id === newItem.id);

      if (existingItem) {
        // Si el producto ya está en el carrito, incrementa la cantidad
        return prevItems.map((item) => (item.id === newItem.id ? { ...item, quantity: item.quantity + 1 } : item));
      } else {
        // Si el producto no está en el carrito, agrégalo con quantity: 1
        return [...prevItems, { ...newItem, quantity: 1 }];
      }
    };

    dispatch(updateCart(handleCart(cart)));
  };

  const removeFromCart = (itemToRemove) => {
    const handleCart = (prevItems) => {
      const existingItem = prevItems.find((item) => item.id === itemToRemove.id);

      if (existingItem) {
        if (existingItem.quantity > 1) {
          // Si el producto tiene más de 1 en cantidad, reduce la cantidad en 1
          return prevItems.map((item) => (item.id === itemToRemove.id ? { ...item, quantity: item.quantity - 1 } : item));
        } else {
          // Si el producto tiene solo 1 en cantidad, lo elimina del carrito
          return prevItems.filter((item) => item.id !== itemToRemove.id);
        }
      }
    };

    dispatch(updateCart(handleCart(cart)));
  };

  const Product = ({ data }) => {
    const existingItem = cart.find((item) => item.id === data.id);

    //const Component = <FloatComponent data={data} existingItem={existingItem} setFloatPanelVisible={setFloatPanelVisible(false)} />;

    return (
      <>
        <div className="product product-cart">
          {/*existingItem && <div className="product-quantity-purchased">{existingItem.quantity}</div>*/}
          <div className="product-image"></div>
          <div className="product-info">
            <div className="product-details">
              <div className="product-name">{data.name}</div>
              <div className="product-description">{data.description}</div>
            </div>
            <div className="product-details-bottom">
              <div className="product-name">{existingItem ? (existingItem.quantity * data.price).toFixed(2) : "0.00"}€</div>
              <div className="product-actions">
                <LuMinus onClick={() => existingItem && removeFromCart(data)} />
                <div className="product-name">{existingItem ? existingItem.quantity : "0"}</div>
                <LuPlus onClick={() => addCart(data)} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const CartResume = () => {
    const getTotalQuantity = () => {
      return cart.reduce((total, item) => total + item.quantity, 0);
    };

    const getTotalPrice = () => {
      return cart.reduce((total, item) => total + item.price * item.quantity, 0);
    };

    const handleMenu = (component) => {
      dispatch(setComponentActive(component));
      navigate("/" + component);
    };

    return (
      <div className="order-commander-totals">
        <div className="order-commander-totals-container">
          <div className="order-commander-totals-row">
            <div className="order-commander-total-row-label">Subtotal</div>
            <div className="order-commander-total-row-info">{getTotalQuantity()} artículos</div>
            <div className="order-commander-total-row-value">{getTotalPrice().toFixed(2)} €</div>
          </div>
          <div className="order-commander-totals-row">
            <div className="order-commander-total-row-label">Domicilio</div>
            <div className="order-commander-total-row-info"> </div>
            <div className="order-commander-total-row-value">0.00 €</div>
          </div>
          <div className="order-commander-totals-row">
            <div className="order-commander-total-row-label">Descuento</div>
            <div className="order-commander-total-row-info"> </div>
            <div className="order-commander-total-row-value">0.00 €</div>
          </div>
          <div className="order-commander-totals-row">
            <div className="order-commander-total-row-label">Impuestos</div>
            <div className="order-commander-total-row-info"> 21% de IVA</div>
            <div className="order-commander-total-row-value">{(getTotalPrice() * 0.21).toFixed(2)} €</div>
          </div>
        </div>
        <div className="order-commander-totals-resume">
          <div className="order-commander-totals-resume-label">Total</div>
          <div className="order-commander-totals-resume-value">{(getTotalPrice() + getTotalPrice() * 0.21).toFixed(2)}€</div>
        </div>
        <div className="order-commander-totals-resume-action">
          {!logged ? (
            <Button text={"Identifícate para continuar"} icon={null} customClass={"customButtonColor2"} action={() => handleMenu("profile")} />
          ) : (
            <Button text={"Hacer pedido"} icon={null} customClass={"customButtonColor2"} action={() => handleMenu("checkout")} />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="cart-container">
      <h2>Carrito</h2>
      <div className="productList">
        {cart.map((item) => (
          <Product data={item} />
        ))}
      </div>
      <CartResume />
    </div>
  );
};
