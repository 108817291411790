import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch } from "react-redux";
import { setUserLogged, setUserRol } from "../../../../reducers/userConfigSlice";
//Components
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { FcGoogle } from "react-icons/fc";
import { Button } from "../../../../components/button";
//Api
//import { googleLogin } from "../../../../api/login";

export const Google = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);
  //profile.picture
  //profile.name
  //profile.email

  /*const logIn = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });*/

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    setProfile(null);
  };

  const handleLogin = async (email) => {
    /*const data = {
      web: window.location.host === "localhost:3010" ? "delibran.com" : window.location.host,
      email: email,
    };
    const response = await googleLogin(data);
    if (response && response.length > 0) {
      console.log(response);
      if (response[0].rol === "admin") {
        dispatch(setUserLogged(true));
        dispatch(setUserRol(response[0].rol));
        navigate("/admin");
      } else {
        dispatch(setUserLogged(true));
        dispatch(setUserRol(response[0].rol));
        navigate("/client");
      }
    }*/
  };

  /*useEffect(() => {
    if (user && user.length > 0) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          setProfile(res.data);
          handleLogin(res.data.email);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);*/

  return profile && profile.length > 0 ? (
    <Button text={"Cerrar sesión con Google"} icon={<FcGoogle />} action={logOut} />
  ) : (
    <Button text={"Iniciar sesión con Google"} icon={<FcGoogle />} action={() => null} />
  );
};
