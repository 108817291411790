import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCart, setComponentActive } from "./../../reducers/userConfigSlice";

import { GoCreditCard } from "react-icons/go";
import { MdOutlineDeliveryDining } from "react-icons/md";
import { CiShop } from "react-icons/ci";
import "./style.css";
import { Button } from "../../components/button";

export const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const DeliveryMethods = () => {
    const [deliveryOption, setDeliveryOption] = useState(0);

    const Card = ({ data }) => {
      let cardUserSelected = "";
      if (data.name === "Domicilio" && deliveryOption === 0) cardUserSelected = "cardUserSelected";
      if (data.name === "Tienda" && deliveryOption === 1) cardUserSelected = "cardUserSelected";
      return (
        <div className={"cardUser " + cardUserSelected} onClick={data.callback}>
          <div className="userIcon">{data.icon}</div>
          <div className="username">{data.name}</div>
        </div>
      );
    };

    return (
      <div className="checkout-block">
        <h2>Selecciona el método de entrega</h2>
        <div className="cartPayments-list">
          <Card data={{ icon: <MdOutlineDeliveryDining />, name: "Domicilio", callback: () => setDeliveryOption(0) }} />
          <Card data={{ icon: <CiShop />, name: "Tienda", callback: () => setDeliveryOption(1) }} />
        </div>
      </div>
    );
  };

  const PaymentMethods = () => {
    const [paymentCard, setPaymentCard] = useState(false);
    const [paymentMoney, setPaymentMoney] = useState(false);
    const [paymentBizum, setPaymentBizum] = useState(false);

    const Card = ({ data }) => {
      let cardUserSelected = "";
      if (data.name === "Tarjeta" && paymentCard === true) cardUserSelected = "cardUserSelected";
      if (data.name === "Efectivo" && paymentMoney === true) cardUserSelected = "cardUserSelected";
      if (data.name === "Bizum" && paymentBizum === true) cardUserSelected = "cardUserSelected";
      return (
        <div className={"cardUser " + cardUserSelected} onClick={data.callback}>
          <div className="userIcon">{data.icon}</div>
          <div className="username">{data.name}</div>
        </div>
      );
    };

    return (
      <div className="checkout-block">
        <h2>Selecciona el método de pago</h2>
        <div className="cartPayments-list">
          <Card data={{ icon: <GoCreditCard />, name: "Tarjeta", callback: () => setPaymentCard(!paymentCard) }} />
          <Card data={{ icon: <GoCreditCard />, name: "Efectivo", callback: () => setPaymentMoney(!paymentMoney) }} />
          <Card data={{ icon: <GoCreditCard />, name: "Bizum", callback: () => setPaymentBizum(!paymentBizum) }} />
        </div>
      </div>
    );
  };

  const CheckoutAction = () => {
    const handleMenu = (component) => {
      console.log("handleMenu: " + component);
      dispatch(setComponentActive(component));
      navigate("/" + component);
    };
    return (
      <div className="checkout-resume-total">
        <Button text={"Pagar"} icon={null} customClass={"customButtonColor2"} action={() => handleMenu("checkoutPage")} />
      </div>
    );
  };

  return (
    <div className="checkout-container">
      <DeliveryMethods />
      <PaymentMethods />
      <CheckoutAction />
    </div>
  );
};
