import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userConfig: {
    logged: false,
    language: "es",
    literals: [],
    rol: null,
    token: null,
    componentActive: "",
    datatable: { configuration: { maxRows: 12, indexStart: 0, pages: [0], filterBy: { section: "", filter: [] } }, checkColumn: false },
    alert: {
      called: false,
      message: "",
      type: "normal",
    },
    floatPanel: {
      visible: false,
      component: <></>,
      data: {},
    },
    order: {},
    cart: [],
  },
};

const userConfigSlice = createSlice({
  name: "aplicationConfig",
  initialState,
  reducers: {
    setAlert: (state, action) => {
      state.userConfig.alert = action.payload;
    },
    setUserLogged: (state, action) => {
      state.userConfig.logged = action.payload;
    },
    setUserLogOut: (state, action) => {
      state.userConfig.logged = false;
      state.userConfig.token = null;
    },
    setUserLanguage: (state, action) => {
      state.userConfig.language = action.payload;
    },
    setLanguage: (state, action) => {
      state.userConfig.literals = action.payload;
    },
    setUserRol: (state, action) => {
      state.userConfig.rol = action.payload;
    },
    setToken: (state, action) => {
      state.userConfig.token = action.payload;
      state.userConfig.logged = true;
    },
    setComponentActive: (state, action) => {
      state.userConfig.floatPanel.visible = false;
      state.userConfig.componentActive = action.payload;
    },
    updateDatatableConfiguration: (state, action) => {
      state.userConfig.datatable.configuration = action.payload;
    },
    updateDatatableFilter: (state, action) => {
      const { section, filter } = action.payload;
      state.userConfig.datatable.configuration.filterBy.section = section;
      state.userConfig.datatable.configuration.filterBy.filter = filter;
    },
    updateDatatableCheckColumn: (state, action) => {
      state.userConfig.datatable.checkColumn = typeof action.payload === "boolean" ? action.payload : !state.userConfig.datatable.checkColumn;
    },
    updateFloatPanelVisibility: (state, action) => {
      state.userConfig.floatPanel.visible = !state.userConfig.floatPanel.visible;
    },
    setFloatPanelComponent: (state, action) => {
      state.userConfig.floatPanel.component = action.payload;
    },
    setFloatPanelData: (state, action) => {
      state.userConfig.floatPanel.data = action.payload;
    },
    setOrder: (state, action) => {
      state.userConfig.order = action.payload;
    },
    updateCart: (state, action) => {
      state.userConfig.cart = action.payload;
    },
  },
});

export const {
  setUserLogged,
  setUserLanguage,
  setLanguage,
  setUserRol,
  setComponentActive,
  updateDatatableConfiguration,
  setToken,
  setUserLogOut,
  setAlert,
  updateDatatableFilter,
  updateDatatableCheckColumn,
  updateFloatPanelVisibility,
  setFloatPanelComponent,
  setFloatPanelData,
  setOrder,
  updateCart,
} = userConfigSlice.actions;
export default userConfigSlice.reducer;
